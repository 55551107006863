import {enqueueSnackbar} from "notistack";
import React, {useEffect, useState} from "react";
import AudioPlayer from "./audio_player";

const TopicDetail = ({setAudioUrl, audioUrl}) => {

    const [languageStates, setLanguageStates] = useState({});
    const [generatingAudio, setGeneratingAudio] = useState({});
    const [articles, setArticles] = useState([]);
    // const [audioUrl, setAudioUrl] = useState('');
    const [creating, setCreating] = useState(false);
    const [audioId, setAudioId] = useState('');

    // setState function is asynchronous, so we need to use a local variable
    let Creating = true;
    let AudioUrl = '';

    // initialize audioUrl,creating from localStorage
    useEffect(() => {
        setAudioUrl(localStorage.getItem('selectedSpeechUrl'))
        setCreating(localStorage.getItem('creating') === 'true')
        setAudioId(localStorage.getItem('selectedSpeechId'))
    }, [])

    // try to get the status of creating audio, try 10 times in 80 seconds
    const checkAudioCreatingTimer = (id) => {
        if (audioId === '' || audioId === undefined) {
            setCreating(true)
            return
        }
        const timer = setInterval(async () => {
            await checkAudioId(id).then(() => {
                if (Creating === false) {
                    setAudioUrl(AudioUrl)
                    localStorage.setItem('selectedSpeechUrl', AudioUrl)
                    localStorage.setItem('creating', 'false')
                    setCreating(false)
                    clearInterval(timer)
                }
            })


        }, 8000);
        setTimeout(() => clearInterval(timer), 80000)
    }

    const checkAudioId = async (id) => {
        const token = localStorage.getItem('token');
        const query = `
        {
          speeches {
            id
            name
            text
            audioUrl
            public
            dateCreated
            creating
          }
        }
      `;

        try {
            const response = await fetch('https://koducks.com/graphql', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...(token && {'Authorization': `Bearer ${token}`}), // Add token if it exists
                },
                body: JSON.stringify({query}),
            });
            const result = await response.json();
            if (result.data && result.data.speeches) {
                result.data.speeches.forEach(speech => {
                    if (speech.id === id) {
                        // setAudioUrl(speech.audioUrl)
                        AudioUrl = speech.audioUrl
                        // setCreating(speech.creating)
                        Creating = speech.creating
                        console.log(speech)
                    }
                })
            } else {
                return null
            }
        } catch (error) {
            enqueueSnackbar('Error fetching speeches.', {variant: "error"})
            return null
        }
    }


    const handleClickForAudio = async (speech, language) => {
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found. User might not be authenticated.");
            enqueueSnackbar("You must log in to generate an audio.", {variant: "warning"});
            // alert("You must log in to generate an audio.");
            return;
        }

        // Immediately set the "creating" flag to true to reflect the UI change
        setCreating(true);
        localStorage.setItem('creating', 'true');
        setGeneratingAudio((prev) => ({...prev, [speech]: true}));
        setArticles((prevArticles) =>
            prevArticles.map((article) =>
                audioId === speech ? {...article, creating: true} : article
            )
        );

        try {
            const response = await fetch("https://koducks.com/api/create_audio", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify({speech, language}),
            });

            if (response.ok) {
                const data = await response.json();
                console.log("Response:", data);
                // Optionally refresh or update the articles after audio creation
                checkAudioCreatingTimer(audioId)
            } else {
                enqueueSnackbar("Failed to generate audio.", {variant: "error"})
                // alert("Failed to generate audio.");
            }
        } catch (error) {
            console.error("Error generating audio:", error);
            enqueueSnackbar("An error occurred. Please try again.", {variant: "error"})
            // alert("An error occurred. Please try again.");
        } finally {
            setGeneratingAudio((prev) => ({...prev, [speech]: false}));
        }
        // console.log("-----------")
        // forTest()
    };

    const handleLanguageSelect = (id, language) => {
        setLanguageStates((prev) => ({...prev, [id]: language}));
    };


    return (
        <>
            {audioUrl !== "null" && audioUrl !== null && audioUrl !== '' ? <AudioPlayer audioUrl={audioUrl}/> :
                <div className="flex items-center mt-4">
                    <div className="relative w-full max-w-5xl mx-auto my-4 rounded-lg bg-gray-100">
                        {/* Conditional rendering for audio */}
                        <div className="flex items-center mt-4">
                            {
                                creating || generatingAudio[audioId] ? ( // Check if audio is being generated
                                    <p className="text-gray-600 italic mt-4 mb-6">Speech audio is being
                                        created...</p>) : (
                                    <>
                                        <select
                                            value={languageStates[audioId] || 'cn'}
                                            onChange={(e) => handleLanguageSelect(audioId, e.target.value)}
                                            className="border border-gray-300 rounded-md p-1 mr-4"
                                        >
                                            <option value="" disabled>Select</option>
                                            <option value="en">English</option>
                                            <option value="cn">Chinese</option>
                                        </select>
                                        <button
                                            className="text-grey-500 hover:text-slate-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition transform hover:scale-105 shadow-md hover:shadow-lg px-3 py-1"
                                            onClick={() => handleClickForAudio(audioId, languageStates[audioId])}
                                        >
                                            Generate Audio
                                        </button>

                                    </>
                                )}
                        </div>
                        {creating  || generatingAudio[audioId] ?'': <p className="text-gray-600 italic mt-4 mb-6 inline" style={{paddingTop: '20px'}}>you have to generate an audio first</p>}
                    </div>
                </div>
            }
        </>

    )
}

export default TopicDetail;
