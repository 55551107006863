import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import CreateArticle from './create_article'
import ArticleList from './articles'


const Create = () => {

    const navigate = useNavigate(); 

    const handleNavigate = () => {
        navigate('/repository'); 
    };

    return (
        <div className="ImproveHere">
            <div className="createArticleContainer">
            <div className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-800 mb-2 md:mb-4 text-center">
                Enter a topic to practice
            </div>
                <CreateArticle />
                <div className="text-sm text-gray-600 text-center mt-2 md:mt-4">
                   
                </div>
                <ArticleList />
                {/* SVG Button below ArticleList to navigate to /repository */}
                <div className="flex justify-center mt-4">
                    <button onClick={handleNavigate}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={`w-6 h-6 transform transition-transform duration-300`}
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19 9l-7 7-7-7"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
};


export default Create;