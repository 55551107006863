import React, { useState, useEffect } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const MainContent = () => {
    const [accessToken, setAccessToken] = useState(localStorage.getItem('token') || null);
    const navigate = useNavigate();

    const handleLoginClick = () => {
        navigate('/login');
    };

    // Function to validate the token
    const validateToken = async (token) => {
        try {
            const response = await fetch('https://koducks.com/api/validate-token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token }),
            });

            const data = await response.json();

            if (response.ok && data.msg === 'Token is valid') {
                // Token is valid, do nothing
                return true;
            } else {
                // Token is invalid or expired
                localStorage.removeItem('token');
                setAccessToken(null);
                return false;
            }
        } catch (error) {
            console.error('Error validating token:', error);
            localStorage.removeItem('token');
            setAccessToken(null);
            return false;
        }
    };

    useEffect(() => {
        const handleStorageChange = () => {
            const token = localStorage.getItem('token');
            setAccessToken(token);
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    useEffect(() => {
        if (accessToken) {
            validateToken(accessToken);
        }
    }, [accessToken]);

    return (
      <div className="flex justify-between items-center h-12 p-2 shadow-sm bg-gray-100">
        <div className="flex items-center space-x-4">
        <a
          href="https://github.com/hjl667/kodee"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm sm:text-base text-gray-900 px-2 sm:px-3 py-1 rounded-lg hover:bg-gray-200 hover:text-gray-700 transition-colors duration-200"
        >
          Use Guide
        </a>
          {!accessToken && (
            <button
              onClick={handleLoginClick}
              className="text-sm sm:text-base text-gray-900 px-2 sm:px-3 py-1 rounded-lg hover:bg-gray-200 hover:text-gray-700 transition-colors duration-200"
            >
              Log In
            </button>
          )}
        </div>
        <div className="flex items-center space-x-4">
          <FaUserCircle
            className="text-xl sm:text-2xl text-gray-700 cursor-pointer"
            onClick={() => navigate('/console')}
          />
        </div>
      </div>
    );
};

export default MainContent;
