import React, { useState, useEffect } from 'react';

const SpeechSelect = ({ setAudioUrl, setSpeech, setTerms, setStructure, setText, setSample }) => {
  const [speeches, setSpeeches] = useState([]);
  const [selectedSpeech, setSelectedSpeech] = useState(null);
  const [hoveredSpeechIndex, setHoveredSpeechIndex] = useState(null);

  useEffect(() => {
    const fetchSpeeches = async () => {
      const token = localStorage.getItem('token');

      // Use a single GraphQL query since the resolver handles both cases
      const query = `
        {
          speeches {
            id
            name
            text
            audioUrl
            public
            terms
            structure
            enSpeech
          }
        }
      `;

      try {
        const response = await fetch('https://koducks.com/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...(token && { 'Authorization': `Bearer ${token}` }), // Add token if it exists
          },
          body: JSON.stringify({ query }),
        });

        const result = await response.json();
        const defaultSpeechId = localStorage.getItem('selectedSpeechId');

        if (result.data && result.data.speeches) {
          const speeches = result.data.speeches;

          // Optionally deduplicate speeches if necessary
          const deduplicatedSpeeches = Array.from(
            speeches.reduce((map, speech) => {
              map.set(speech.id, speech); // Map will automatically overwrite any duplicate keys
              return map;
            }, new Map()).values()
          );

          setSpeeches(deduplicatedSpeeches);

          // Handle the default selected speech if available
          if (defaultSpeechId) {
            const defaultSpeech = deduplicatedSpeeches.find(speech => speech.id === defaultSpeechId);
            if (defaultSpeech) {
              handleSpeechSelect(defaultSpeech);
            }
          }
        } else {
          console.error('Failed to fetch speeches:', result.errors);
        }
      } catch (error) {
        console.error('Error fetching speeches:', error);
      }
    };

    fetchSpeeches();
  }, []);


  const handleSpeechSelect = (speech) => {
    setSelectedSpeech(speech);
    setAudioUrl(speech.audioUrl);
    setSpeech(speech.id);
    setTerms(speech.terms);
    setStructure(speech.structure);
    setText(speech.text)
    setSample(speech.enSpeech);

    // Store the selected speech ID in localStorage
    localStorage.setItem('selectedSpeechId', speech.id);
  };

  return (
    <div className=" sm:w-3/4 max-w-md mx-auto mt-6">
      {/*remove the className attribute (w-2/4) of upper dom*/}
      <div className="relative">
        {/* the origin font size is 0.825rem */}
        <select
            style={{
              fontSize:"0.75rem"
            }}
          value={selectedSpeech ? speeches.findIndex(speech => speech.id === selectedSpeech.id) : ''}
          onChange={(e) => handleSpeechSelect(speeches[e.target.value])}
          onMouseEnter={(e) => setHoveredSpeechIndex(e.target.selectedIndex - 1)}
          onMouseLeave={() => setHoveredSpeechIndex(null)}
          className="w-full p-2 border-2 border-gray-300 rounded-lg outline-none appearance-none bg-white cursor-pointer text-sm sm:text-base"
        >
          <option value="">Select a Speech</option>
          {speeches.map((speech, index) => (
            <option key={speech.id} value={index}>
              {speech.name}
            </option>
          ))}
        </select>

      </div>

    </div>
  );
};

export default SpeechSelect;
