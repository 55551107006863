import React, { useState, useEffect } from 'react';
import CollapsibleSection from '../../routes/Playground/section'

const MarkdownDisplay = () => {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    const storedFeedback = localStorage.getItem('formattedFeedback');
    let markdownContent = "# Feedback:\n\nThis is where feedback will appear.";

    try {
      const parsedFeedback = storedFeedback ? JSON.parse(storedFeedback) : null;
      markdownContent = parsedFeedback || markdownContent;
      markdownContent = markdownContent.replace(/\\n/g, '\n');

      // Split content into sections based on h1 headings
      const sectionRegex = /^# .+$/gm;
      const sectionTitles = markdownContent.match(sectionRegex) || [];
      const sectionContents = markdownContent.split(sectionRegex).slice(1);

      const newSections = [];

      sectionTitles.forEach((title, index) => {
        const cleanTitle = title.replace('# ', '');
        let content = sectionContents[index].trim();

        if (cleanTitle === "原文：") {
          const demonstrationIndex = content.indexOf("**示范**");
          if (demonstrationIndex !== -1) {
            // Add the original content section
            newSections.push({
              title: cleanTitle,
              content: content.slice(0, demonstrationIndex).trim()
            });

            // Add the demonstration section
            newSections.push({
              title: "示范",
              content: content.slice(demonstrationIndex).trim()
            });
          } else {
            // If "**示范**" is not found, add the section as is
            newSections.push({ title: cleanTitle, content });
          }
        } else {
          // For other sections, add them as they are
          newSections.push({ title: cleanTitle, content });
        }
      });

      setSections(newSections);
    } catch (error) {
      console.error("Failed to parse stored feedback:", error);
    }
  }, []);

  const downloadMarkdown = () => {
    const fullContent = sections.map(section => `# ${section.title}\n\n${section.content}`).join('\n\n');
    const blob = new Blob([fullContent], { type: 'text/markdown' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'feedback.md';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="relative w-full max-w-5xl mx-auto my-4 rounded-lg bg-gray-100">
      <div className="flex">
        <div className="w-1/4 p-4 bg-gray-100 rounded-lg flex flex-col hidden sm:block">
          <button
            onClick={downloadMarkdown}
            className="bg-black text-white text-xs py-1 px-2 mb-8 rounded hover:bg-gray-600 self-start"
          >
            Download
          </button>
          <h3 className="text-lg font-semibold mb-4">Contents</h3>
          <ul>
            {sections.map((section, index) => (
              <li key={index}>
                <a href={`#${section.title.toLowerCase().replace(/\s+/g, '-')}`} className="text-gray-800 hover:underline">
                  {section.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full sm:w-full h-[680px] overflow-y-auto text-base bg-gray-100 text-gray-800 rounded-lg">
          {sections.map((section, index) => (
            <CollapsibleSection key={index} title={section.title} content={section.content} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MarkdownDisplay;
