import React, { useState, useEffect, useRef } from 'react';
import './playground.css';
import Recording from './Recording'
import AudioPlayer from './audio_player';
import Transcription from './Feedback';
import SpeechSelect from './search_speech';
import CollapsibleSection from './section';
import TopicDetail from "./TopicDetail";


const SpeechManagement = () => {
    const [audioUrl, setAudioUrl] = useState(null);
    const [interimTranscription, setInterimTranscription] = useState('');
    const [finalTranscription, setFinalTranscription] = useState('');
    const [speech, setSpeech] = useState('');
    const [transcription, setTranscription] = useState('');
    const [terms, setTerms] = useState('');
    const [text, setText] = useState('');
    const [structure, setStructure] = useState('');
    const [sample, setSample] = useState('');
    const [showTerms, setShowTerms] = useState(true); // State to toggle terms visibility

    useEffect(() => {
        // Retrieve the selected audio URL from localStorage
        const storedAudioUrl = localStorage.getItem('selectedSpeechUrl');
        if (storedAudioUrl) {
            setAudioUrl(storedAudioUrl);
        }
    }, []);

    const handleToggleTerms = () => {
        setShowTerms(prevState => !prevState); // Toggle visibility
    };


    return (
        <div className="ImproveHere">
            <div className="expandedContainer">
                <TopicDetail
                  setAudioUrl={setAudioUrl}
                  audioUrl={audioUrl}
                />

                {/* Container for SpeechSelect and Recording , 24/10/2 remove the attribute of className w-2/4 */}
                <div className="flex items-center space-x-4 sm:w-3/4 max-w-md mx-auto mt-6 " style={{
                    width : "90%"
                }}>
                <SpeechSelect
                    setAudioUrl={setAudioUrl}
                    setSpeech={setSpeech}
                    setTerms={setTerms}
                    setStructure={setStructure}
                    setText={setText}
                    setSample={setSample}
                />
                <Recording
                    setInterimTranscription={setInterimTranscription}
                    setFinalTranscription={setFinalTranscription}
                />
            </div>

                {/* Button to toggle between showing terms and transcription */}
                <button
                    onClick={handleToggleTerms}
                    className="mt-4 p-2 bg-transparent text-black hover:bg-gray-100 rounded"
                    title="Click to see transcription and get feedback"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={`w-6 h-6 transform ${showTerms ? 'rotate-180' : ''} transition-transform duration-300`}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                    </svg>
                </button>

                {/* Conditionally show terms or transcription */}
                {showTerms ? (
                    <div className="container mx-auto p-4">
                    <div className="max-h-[450px] overflow-y-auto space-y-4">
                      {terms && (
                        <CollapsibleSection
                          title="Terms"
                          content={terms}
                        />
                      )}
                      {structure && (
                        <CollapsibleSection
                          title="Outline"
                          content={structure}
                        />
                      )}
                      {sample && (
                        <CollapsibleSection
                          title="English Sample"
                          content={sample}
                        />
                      )}
                      {text && (
                        <CollapsibleSection
                          title="Full Text"
                          content={text}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                    <Transcription
                        setTranscription={setTranscription}
                        interimTranscription={interimTranscription}
                        finalTranscription={finalTranscription}
                        speech={speech}
                    />
                )}
            </div>
        </div>
    );
};



export default SpeechManagement;
