import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Card, CardContent, Typography, Box} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';



const ArticleList = () => {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [selectedArticleId, setSelectedArticleId] = useState(null);
  const articleGridRef = useRef(null);

  const isLargeScreen = useMediaQuery('(min-width:1060px)');
  const isMediumScreen = useMediaQuery('(min-width:1060px)');
  const isSmallScreen = useMediaQuery('(min-width:900px)');

  // Determine the number of articles to display based on screen size
  let maxDisplayArticles = 6;
  if (isLargeScreen) {
    maxDisplayArticles = 5; // Display more articles on large screens
  } else if (isMediumScreen) {
    maxDisplayArticles = 4; // Display fewer articles on medium screens
  } else if (isSmallScreen) {
    maxDisplayArticles = 2; // Display fewer articles on small screens
  } else {
    maxDisplayArticles = 6; // Display the fewest articles on very small screens
  }



  const navigate = useNavigate();

  useEffect(() => {
    const fetchSpeeches = async () => {
      const token = localStorage.getItem('token');
      
      const query = `
        {
          speeches {
            id
            name
            text
            audioUrl
            public
          }
        }
      `;
  
      try {
        const response = await fetch('https://koducks.com/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...(token && { 'Authorization': `Bearer ${token}` }), // Add token if it exists
          },
          body: JSON.stringify({ query }),
        });
  
        const result = await response.json();
  
        if (result.data && result.data.speeches) {
          // Now all speeches are in a single array from the same resolver
          const speeches = result.data.speeches;
  
          // Optionally filter or process speeches if needed
          setArticles(speeches);
          setFilteredArticles(speeches);
        } else {
          console.error('Failed to fetch speeches:', result.errors);
        }
      } catch (error) {
        console.error('Error fetching speeches:', error);
      }
    };
  
    fetchSpeeches();
  }, []);

  const handleCardClick = (id, url) => {
    localStorage.setItem('selectedSpeechUrl', url);
    localStorage.setItem('selectedSpeechId', id);

    setSelectedArticleId(id);
    console.log(`Saved speech ID: ${id} to local storage`);
    navigate('/playground');
  };

  function shuffleArray(array) {
    return array.sort(() => Math.random() - 0.5);
  }
  
  const selectedArticles = useMemo(() => {
    // Shuffle the filtered articles and select the first four
    return shuffleArray(filteredArticles).slice(0, 8);
  }, [filteredArticles]);

  return (
    <div className="w-4/5 max-w-full sm:max-w-[100%] md:max-w-[80%] lg:max-w-[1280px] mx-auto p-3 md:p-3 text-center relative">
      <div className="overflow-x-auto pb-2 transition-all duration-200 no-scrollbar" ref={articleGridRef}>
        <div className="flex flex-col sm:flex-row gap-2 md:gap-2.5 p-2 transition-all duration-200 items-stretch sm:items-center">
          {selectedArticles.slice(0, maxDisplayArticles).map((article, index) => (
            <div
              key={index}
              className={`w-full sm:w-full md:w-auto min-w-full sm:min-w-[100%] md:min-w-[140px] h-[38px] md:h-[200px]  relative overflow-hidden cursor-pointer transform transition-transform duration-200  ${
                selectedArticleId === article.id ? 'border-4 border-gray-300' : ''
              }`}
              onClick={() => handleCardClick(article.id, article.audioUrl)}
            >
              <div className="absolute bottom-0 left-0 right-0 h-[30%]  from-white to-transparent"></div>
              <div>
                <h3 className="mb-2 mt-4 mr-2 ml-2 text-gray-800 text-sm md:text-base font-semibold">
                  {article.name}
                </h3>
              
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ArticleList;